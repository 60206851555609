<template>
  <div class="box">
    <van-nav-bar
      title="合作伙伴"
      left-text=""
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="title">行业名称</div>
    <ul>
      <li><img src="../assets/b1.png" alt="" /></li>
      <li><img src="../assets/b2.png" alt="" /></li>
      <li><img src="../assets/b3.png" alt="" /></li>
    </ul>
    <div class="title">房产物业</div>
    <ul>
      <li><img src="../assets/b4.png" alt="" /></li>
      <li><img src="../assets/b5.png" alt="" /></li>
      <li><img src="../assets/b6.png" alt="" /></li>
    </ul>
    <div class="title">金融资本</div>
    <ul>
      <li><img src="../assets/b7.png" alt="" /></li>
      <li><img src="../assets/b8.png" alt="" /></li>
    </ul>
    <div class="title">其他行业</div>
    <ul>
      <li><img src="../assets/b9.png" alt="" /></li>
      <li><img src="../assets/b10.png" alt="" /></li>
      <li><img src="../assets/b11.png" alt="" /></li>
      <li><img src="../assets/b12.png" alt="" /></li>
      <li><img src="../assets/b13.png" alt="" /></li>
      <li><img src="../assets/b14.png" alt="" /></li>
      <li><img src="../assets/b15.png" alt="" /></li>
    </ul>
  </div>
</template>

<script>
export default {
  methods: {
    onClickLeft() {
      Toast("返回");
    },
  },
};
</script>

<style scoped>
.box {
  width: 100%;
  background: #f5f7fa;
}
.title {
  font-size: 0.32rem;
  margin-left: 0.2rem;
  margin-top: 0.32rem;
  margin-bottom: 0.34rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}
ul {
  padding: 0 0.22rem;
  /* display: flex;
  flex-wrap: wrap;
  justify-content: space-around; */
}
ul li {
  display: inline-block;

  width: 2.35rem;
  height: 1.2rem;
}
ul li img {
  width: 100%;
}
ul li {
  margin-bottom: 0.2rem;
}
</style>